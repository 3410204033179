var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "initial-thought", attrs: { xs12: "" } },
                [
                  _c(
                    "h3",
                    { staticClass: "starling-h3 StarlingDarkGrey--text" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.tools.thought_balancer.tabs.tool.fields.initial.title"
                          )
                        )
                      )
                    ]
                  ),
                  _c("p", { staticClass: "starling-instructional mt-1" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "app.tools.thought_balancer.tabs.tool.fields.initial.subtitle"
                        )
                      )
                    )
                  ]),
                  _c("v-textarea", {
                    staticClass: "mt-2",
                    attrs: {
                      rules: _vm.rules.textFieldRules,
                      label: _vm.$t(
                        "app.tools.thought_balancer.tabs.tool.fields.initial.label"
                      ),
                      hint: _vm.$t(
                        "app.tools.thought_balancer.tabs.tool.fields.initial.helperText"
                      ),
                      rows: "1",
                      "persistent-hint": "",
                      counter: 1024,
                      "auto-grow": ""
                    },
                    model: {
                      value: _vm.form.initial,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "initial", $$v)
                      },
                      expression: "form.initial"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider", { staticClass: "my-3", attrs: { light: "" } }),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "facts-for", attrs: { xs12: "" } },
                [
                  _c(
                    "h3",
                    { staticClass: "starling-h3 StarlingDarkGrey--text" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.tools.thought_balancer.tabs.tool.fields.factsFor.title"
                          )
                        )
                      )
                    ]
                  ),
                  _c("p", { staticClass: "starling-instructional mt-1" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "app.tools.thought_balancer.tabs.tool.fields.factsFor.subtitle"
                        )
                      )
                    )
                  ]),
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      name: "firstFactsFor",
                      rules: _vm.rules.firstFactsRules,
                      counter: 255,
                      label: _vm.$t(
                        "app.tools.thought_balancer.tabs.tool.fields.factsFor.label"
                      ),
                      hint: _vm.$t(
                        "app.tools.thought_balancer.tabs.tool.fields.factsFor.helperText"
                      ),
                      "persistent-hint": ""
                    },
                    on: {
                      keypress: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.triggerEnter($event)
                      }
                    },
                    model: {
                      value: _vm.form.factsFor[0].title,
                      callback: function($$v) {
                        _vm.$set(_vm.form.factsFor[0], "title", $$v)
                      },
                      expression: "form.factsFor[0].title"
                    }
                  }),
                  _vm._l(_vm.otherFactsFor, function(thought, index) {
                    return _c("v-text-field", {
                      key: index,
                      staticClass: "additional-field",
                      attrs: {
                        name: "FactsFor",
                        rules: _vm.rules.factsRules,
                        counter: 255,
                        "append-icon": "close"
                      },
                      on: {
                        keypress: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.triggerEnter($event)
                        },
                        "click:append": function($event) {
                          return _vm.removeField(true, index + 1)
                        }
                      },
                      model: {
                        value: _vm.otherFactsFor[index].title,
                        callback: function($$v) {
                          _vm.$set(_vm.otherFactsFor[index], "title", $$v)
                        },
                        expression: "otherFactsFor[index].title"
                      }
                    })
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-3 font-weight-bold",
                      attrs: { block: "", flat: "", color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.addField(true)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "app.tools.thought_balancer.tabs.tool.buttons.add_another"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ],
                2
              )
            ],
            1
          ),
          _c("v-divider", { staticClass: "my-3", attrs: { light: "" } }),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "facts-against", attrs: { xs12: "" } },
                [
                  _c(
                    "h3",
                    { staticClass: "starling-h3 StarlingDarkGrey--text" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.tools.thought_balancer.tabs.tool.fields.factsAgainst.title"
                          )
                        )
                      )
                    ]
                  ),
                  _c("p", { staticClass: "starling-instructional mt-1" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "app.tools.thought_balancer.tabs.tool.fields.factsAgainst.subtitle"
                        )
                      )
                    )
                  ]),
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      name: "firstFactsAgainst",
                      rules: _vm.rules.firstFactsRules,
                      counter: 255,
                      label: _vm.$t(
                        "app.tools.thought_balancer.tabs.tool.fields.factsAgainst.label"
                      ),
                      hint: _vm.$t(
                        "app.tools.thought_balancer.tabs.tool.fields.factsAgainst.helperText"
                      ),
                      "persistent-hint": ""
                    },
                    on: {
                      keypress: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.triggerEnter($event)
                      }
                    },
                    model: {
                      value: _vm.form.factsAgainst[0].title,
                      callback: function($$v) {
                        _vm.$set(_vm.form.factsAgainst[0], "title", $$v)
                      },
                      expression: "form.factsAgainst[0].title"
                    }
                  }),
                  _vm._l(_vm.otherFactsAgainst, function(thought, index) {
                    return _c("v-text-field", {
                      key: index,
                      staticClass: "additional-field",
                      attrs: {
                        name: "FactsAgainst",
                        rules: _vm.rules.factsRules,
                        counter: 255,
                        "append-icon": "close",
                        "persistent-hint": ""
                      },
                      on: {
                        keypress: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.triggerEnter($event)
                        },
                        "click:append": function($event) {
                          return _vm.removeField(false, index + 1)
                        }
                      },
                      model: {
                        value: _vm.otherFactsAgainst[index].title,
                        callback: function($$v) {
                          _vm.$set(_vm.otherFactsAgainst[index], "title", $$v)
                        },
                        expression: "otherFactsAgainst[index].title"
                      }
                    })
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-3 font-weight-bold",
                      attrs: { block: "", flat: "", color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.addField(false)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "app.tools.thought_balancer.tabs.tool.buttons.add_another"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ],
                2
              )
            ],
            1
          ),
          _c("v-divider", { staticClass: "my-3", attrs: { light: "" } }),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "final-thought", attrs: { xs12: "" } },
                [
                  _c(
                    "h3",
                    { staticClass: "starling-h3 StarlingDarkGrey--text" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.tools.thought_balancer.tabs.tool.fields.balanced.title"
                          )
                        )
                      )
                    ]
                  ),
                  _c("p", { staticClass: "starling-instructional mt-1" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "app.tools.thought_balancer.tabs.tool.fields.balanced.subtitle"
                        )
                      )
                    )
                  ]),
                  _c("v-textarea", {
                    staticClass: "mt-2",
                    attrs: {
                      rules: _vm.rules.textFieldRules,
                      label: _vm.$t(
                        "app.tools.thought_balancer.tabs.tool.fields.balanced.label"
                      ),
                      hint: _vm.$t(
                        "app.tools.thought_balancer.tabs.tool.fields.balanced.helperText"
                      ),
                      rows: "1",
                      "persistent-hint": "",
                      counter: 1024,
                      "auto-grow": ""
                    },
                    model: {
                      value: _vm.form.balanced,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "balanced", $$v)
                      },
                      expression: "form.balanced"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        {
          attrs: { row: "", wrap: "", "justify-center": "", "align-center": "" }
        },
        [
          _c(
            "v-flex",
            { staticClass: "text-center mt-4", attrs: { xs12: "" } },
            [
              _c(
                "primary-button",
                {
                  attrs: {
                    id: "btn-thought-form-save",
                    disabled: !_vm.valid,
                    loading: _vm.loading,
                    large: !_vm.inline
                  },
                  on: { click: _vm.submitAction }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.inline
                          ? _vm.$t("app.tools.thought_balancer.actions.save")
                          : _vm.$t("common.actions.save")
                      ) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _vm.cancellable
            ? _c(
                "v-flex",
                { staticClass: "text-center mt-3", attrs: { xs12: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: _vm.loading, flat: "" },
                      on: { click: _vm.cancel }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("common.actions.cancel")) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }