<template>
<v-container fluid pa-0>
  <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
    <v-layout row wrap>
      <v-flex xs12 class="initial-thought">
        <h3 class="starling-h3 StarlingDarkGrey--text">{{ $t('app.tools.thought_balancer.tabs.tool.fields.initial.title') }}</h3>
        <p class="starling-instructional mt-1">{{ $t('app.tools.thought_balancer.tabs.tool.fields.initial.subtitle') }}</p>

        <v-textarea
                class="mt-2"
                v-model="form.initial"
                :rules="rules.textFieldRules"
                :label="$t('app.tools.thought_balancer.tabs.tool.fields.initial.label')"
                :hint="$t('app.tools.thought_balancer.tabs.tool.fields.initial.helperText')"
                rows="1"
                persistent-hint
                :counter="1024"
                auto-grow>
        </v-textarea>
      </v-flex>
    </v-layout>

    <v-divider light class="my-3"></v-divider>

    <v-layout row wrap>
      <v-flex xs12 class="facts-for">
        <h3 class="starling-h3 StarlingDarkGrey--text">{{ $t('app.tools.thought_balancer.tabs.tool.fields.factsFor.title') }}</h3>
        <p class="starling-instructional mt-1">{{ $t('app.tools.thought_balancer.tabs.tool.fields.factsFor.subtitle') }}</p>
        <v-text-field
                class="mt-2"
                name="firstFactsFor"
                v-model="form.factsFor[0].title"
                @keypress.enter="triggerEnter"
                :rules="rules.firstFactsRules"
                :counter="255"
                :label="$t('app.tools.thought_balancer.tabs.tool.fields.factsFor.label')"
                :hint="$t('app.tools.thought_balancer.tabs.tool.fields.factsFor.helperText')"
                persistent-hint>
        </v-text-field>

        <v-text-field
                name="FactsFor"
                v-for="(thought, index) in otherFactsFor"
                v-model="otherFactsFor[index].title"
                @keypress.enter="triggerEnter"
                @click:append="removeField(true, index + 1)"
                :rules="rules.factsRules"
                :counter="255"
                :key="index"
                append-icon="close"
                class="additional-field">
        </v-text-field>

        <v-btn block flat color="primary" @click="addField(true)" class="mt-3 font-weight-bold">
          {{$t('app.tools.thought_balancer.tabs.tool.buttons.add_another')}}
        </v-btn>
      </v-flex>
    </v-layout>

    <v-divider light class="my-3"></v-divider>

    <v-layout row wrap>
      <v-flex xs12 class="facts-against">
        <h3 class="starling-h3 StarlingDarkGrey--text">{{ $t('app.tools.thought_balancer.tabs.tool.fields.factsAgainst.title') }}</h3>
        <p class="starling-instructional mt-1">{{ $t('app.tools.thought_balancer.tabs.tool.fields.factsAgainst.subtitle') }}</p>
        <v-text-field
                class="mt-2"
                name="firstFactsAgainst"
                v-model="form.factsAgainst[0].title"
                @keypress.enter="triggerEnter"
                :rules="rules.firstFactsRules"
                :counter="255"
                :label="$t('app.tools.thought_balancer.tabs.tool.fields.factsAgainst.label')"
                :hint="$t('app.tools.thought_balancer.tabs.tool.fields.factsAgainst.helperText')"
                persistent-hint>
        </v-text-field>

        <v-text-field
                name="FactsAgainst"
                v-for="(thought, index) in otherFactsAgainst"
                v-model="otherFactsAgainst[index].title"
                @keypress.enter="triggerEnter"
                @click:append="removeField(false, index + 1)"
                :rules="rules.factsRules"
                :counter="255"
                :key="index"
                append-icon="close"
                class="additional-field"
                persistent-hint>
        </v-text-field>

        <v-btn block flat color="primary" @click="addField(false)" class="mt-3 font-weight-bold">
          {{$t('app.tools.thought_balancer.tabs.tool.buttons.add_another')}}
        </v-btn>
      </v-flex>
    </v-layout>

    <v-divider light class="my-3"></v-divider>

    <v-layout row wrap>
      <v-flex xs12 class="final-thought">
        <h3 class="starling-h3 StarlingDarkGrey--text">{{ $t('app.tools.thought_balancer.tabs.tool.fields.balanced.title') }}</h3>
        <p class="starling-instructional mt-1">{{ $t('app.tools.thought_balancer.tabs.tool.fields.balanced.subtitle') }}</p>
        <v-textarea class="mt-2"
                    v-model="form.balanced"
                    :rules="rules.textFieldRules"
                    :label="$t('app.tools.thought_balancer.tabs.tool.fields.balanced.label')"
                    :hint="$t('app.tools.thought_balancer.tabs.tool.fields.balanced.helperText')"
                    rows="1"
                    persistent-hint
                    :counter="1024"
                    auto-grow>
        </v-textarea>
      </v-flex>
    </v-layout>
  </v-form>

  <v-layout row wrap justify-center align-center>
    <v-flex xs12 class="text-center mt-4">
      <primary-button
              id="btn-thought-form-save"
              :disabled="!valid"
              :loading="loading"
              @click="submitAction"
              :large="!inline">
        {{ inline ? $t('app.tools.thought_balancer.actions.save') : $t('common.actions.save')}}
      </primary-button>
    </v-flex>
    <v-flex xs12 class="text-center mt-3" v-if="cancellable">
      <v-btn
              :disabled="loading"
              @click="cancel"
              flat>
        {{$t('common.actions.cancel')}}
      </v-btn>
    </v-flex>
  </v-layout>
</v-container>
</template>

<script>
import PrimaryButton from '@/views/components/button/primary-button';
export default {
  name: 'thought-form',
  components: { PrimaryButton },
  props: {
    thought: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    cancellable: {
      type: Boolean,
      required: false,
      default: false,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      valid: false,
      form: {},
      rules: {
        textFieldRules: [ v => !!v || this.$t('common.errors.required'),
          v => v.length <= 1024 || this.$t('common.errors.max1024') ],
        firstFactsRules: [ v => !!v || this.$t('common.errors.required'),
          v => v.length <= 255 || this.$t('common.errors.max255'),
        ],
        factsRules: [ v => v.length <= 255 || this.$t('common.errors.max255') ],
      },
      firstFactsForError: false,
      firstFactsAgainstError: false,
    };
  },
  computed: {
    otherFactsFor() {
      return this.form.factsFor.slice(1);
    },
    otherFactsAgainst() {
      return this.form.factsAgainst.slice(1);
    },
  },
  watch: {
    thought: {
      immediate: true,
      handler(newValue) {
        this.form = {
          id: newValue.id || null,
          initial: newValue.initial || '',
          factsFor: newValue.factsFor || [ { title: '' } ],
          factsAgainst: newValue.factsAgainst || [ { title: '' } ],
          balanced: newValue.balanced || '',
        };
        if (this.$refs && this.$refs.form) {
          this.$refs.form.resetValidation();
        }
      },
    },
  },
  methods: {
    removeField(value, index) {
      if (value) {
        this.form.factsFor.splice(index, 1);
        this.$nextTick(() => {
          this.focusFactsFor();
        });
      } else {
        this.form.factsAgainst.splice(index, 1);
        this.$nextTick(() => {
          this.focusFactsAgainst();
        });
      }
    },
    addField(value) {
      if (value && this.form.factsFor[0].title.length) {
        this.form.factsFor.push({ title: '' });
        this.$nextTick(() => {
          this.focusFactsFor();
        });
      } else {
        if (this.form.factsAgainst[0].title.length) {
          this.form.factsAgainst.push({ title: '' });
          this.$nextTick(() => {
            this.focusFactsAgainst();
          });
        }
      }
    },
    submitAction() {
      if (this.$refs.form.validate()) {
        this.filterFacts();
        this.$emit('thought-submit', Object.assign({}, this.form));
      }
    },
    triggerEnter(e) {
      this.addField(e.target.name.includes('FactsFor'));
    },
    filterFacts() {
      this.form.factsFor = this.form.factsFor.filter(fact => fact.title.length > 0);
      this.form.factsAgainst = this.form.factsAgainst.filter(fact => fact.title.length > 0);
    },
    focusFactsFor() {
      const inputs = this.$el.querySelectorAll('.facts-for > .v-input');
      const input = inputs[inputs.length - 1].querySelector('input');
      input.focus();
    },
    focusFactsAgainst() {
      const inputs = this.$el.querySelectorAll('.facts-against > .v-input');
      const input = inputs[inputs.length - 1].querySelector('input');
      input.focus();
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>
